import React from "react";
import Layout from "../components/layout";
import { TermsConditions } from "../components/privacy-policy";

const TermsConditionsPage = () => (
  <Layout>
    <TermsConditions />
  </Layout>
);

export default TermsConditionsPage;
